// Text
.normal-size {
  font-size: 1rem;
  font-weight: normal;
}

// utils
.cursor-pointer {
  cursor: pointer;
}

// Margin override
.mt-4,
.my-4 {
  margin-top: 30px !important;
}

// Badge
table .badge {
  font-size: 80%;
  padding: 0.4em 0.45em 0.3em;
  font-weight: 500;
}

// Grid
@media (min-width: 992px) {
  .container {
    max-width: 980px;
  }
}

// Navbar
.navbar-custom {
  .dropdown.show {
    background-color: #38404b;
  }
}

.topnav-logo {
  .topnav-version {
    font-size: 0.66rem;
    color: $gray-900;
    margin-left: 0.5rem;
  }
  img {
    max-height: 2.8rem;
  }
}

.topbar-nav {
  font-weight: 600;

  .side-nav-link {
    padding: 0.832rem 1rem;
    i {
      // font-size: 16px;
      vertical-align: baseline;
    }
  }

  .side-nav-link i.mdi {
    font-size: 18px;
    vertical-align: middle;

    &.mdi-bank-transfer-in,
    &.mdi-bank-transfer-out,
    &.mdi-bank-transfer {
      font-size: 22px;
    }
  }

  a .badge-pill {
    font-size: 9px;
    top: -10px;
    position: relative;
    padding: 3px 5px 2px;
    text-transform: uppercase;

    &.badge-light {
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
}

// Nav User
.nav-user,
.nav-menu-topbar {
  // padding: calc(100 / 2) 0 0 0 !important;
  padding-left: 0rem !important;
  padding-right: 0rem !important;
  margin-left: 1rem !important;
  margin-right: 1rem !important;
  min-width: inherit !important;
  top: 1px !important;

  &.perfil {
    margin-left: 15px !important;
    top: 1px !important;
  }
}

.nav-user .account-user-avatar,
.nav-user .private-menu,
.nav-menu-topbar .title-menu-topbar {
  position: relative;
  top: inherit;
  left: inherit;
}

// Cards
.card {
  &.bordered {
    border: 1px solid #eee;
  }

  &.border-top {
    border-color: $gray-100 !important;
  }
}

.card-title,
.card-header {
  cursor: pointer;
}

// Links
.btn-outline-secondary {
  border-color: #e3eaef;
  background-color: white;
}
// .btn {
//   font-size: 1rem;
// }

// Button Group
// .btn-group {
//   .dropdown-menu {
//     top: 1px !important;
//   }
// }

// Bloco Resumo
.bloco {
  // min-height: 180px;
  .with-border {
    border-right: 1px solid #ebedee;
  }
}

@include media-breakpoint-down(md) {
  .bloco-resumo {
    margin-bottom: 2rem;
  }

  .bloco {
    .card-body .row > div {
      border: none;
    }
  }
}

.min-height-bloco {
  min-height: 350px;
}

// React Select
.react-select {
  .react-select__control {
    cursor: pointer !important;
    // margin-right: 1rem;
  }

  .react-select__menu {
    .react-select__option {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .react-select__value-container {
    border-right: 1px solid $gray-200;
  }

  &.readable {
    padding: 0;
    margin: 0;
    border-bottom: 1px solid $gray-300;
    line-height: 1;
    min-height: 1.5rem;
    margin-bottom: 1rem;

    > div {
      border: none;
      background-color: transparent;
      border-radius: 0;
      padding: 0;
      margin: 0;
      min-height: inherit;
      display: inline;
      line-height: inherit;

      * {
        padding: 0;
        margin: 0;
        color: $dark;
      }
    }

    .react-select__single-value--is-disabled {
      line-height: 1rem;
    }

    .react-select__indicators {
      display: none;
    }

    .react-select__value-container {
      border: none;
      padding: 0;
    }
  }

  &.dropdown {
    min-width: 220px;
    margin-top: 1.1rem;
  }

  &.dark {
    &.dropdown {
      @include media-breakpoint-up(md) {
        min-width: 320px;
      }

      @media (max-width: 600px) {
        min-width: 150px;
      }
    }

    .react-select__control {
      background-color: rgba(255, 255, 255, 0.1);
      border: none;
      color: $gray-600;
    }

    .react-select__value-container {
      border-right: 1px solid rgba(255, 255, 255, 0.1);
    }

    .react-select__input,
    .react-select__single-value,
    .react-select__placeholder {
      color: $gray-600;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .react-select__dropdown-indicator {
      &:hover {
        color: $gray-600;
      }
    }
  }

  .react-select__indicator {
    color: $gray-700;
  }

  .react-select__multi-value {
    width: 100%;
    background-color: $gray-300 !important;

    &__label {
      width: 100%;
      font-size: 100%;
      color: $black !important;
    }

    &__remove {
      color: $gray-800 !important;

      &:hover {
        color: $gray-800 !important;
        background-color: $gray-400 !important;
      }
    }
  }
}

// Select
.react-select__option {
  &:hover,
  &.active {
    background-color: $primary !important;
    color: white !important;
  }
}

.react-select__option--is-selected {
  background-color: $gray-300 !important;
}

.react-select__option--is-focused {
  background-color: $gray-300 !important;
}

.react-select {
  > div:not(:first-of-type) {
    border: none;

    &:hover {
      border: none;
    }
  }
}

// Topbar
.navbar-custom {
  .account-user-avatar,
  .private-menu,
  .title-menu-topbar {
    .icon {
      font-size: 1.5rem;
    }
  }

  .side-nav-item {
    border-bottom: 1px solid $gray-600;
  }
}

// Tabs
.nav-tabs {
  .nav-item {
    a {
      cursor: pointer;
    }
  }

  a.nav-link {
    color: $primary;
  }
}

// Menu Header
.dropdown-header {
  p {
    white-space: normal;
  }
}

.escritorio-contabil-header {
  max-width: 17rem;
  margin-top: 1.7rem;
  color: $gray-600;
  text-align: right;

  @media (max-width: 1410px) {
    max-width: 10rem;
  }
}

// Table
.table > tbody > tr > td {
  vertical-align: middle;
  height: 4.375rem;
}

.table-livro-razao {
  // color: $gray-900;
  th {
    border-top: none;
  }

  th,
  td {
    width: auto;
  }

  th:nth-child(2),
  td:nth-child(2) {
    width: 100%;
  }

  th,
  td {
    padding: 0.5rem 1.4rem;
  }

  th:first-child(),
  td:first-child() {
    padding: 0.5rem 1.4rem 0.5rem 0.5rem;
  }

  th:last-child(),
  td:last-child() {
    padding: 0.5rem 0.5rem 0.5rem 1.4rem;
  }
}

.table-balancete {
  $padding-table-balancete: 1.4;

  th {
    white-space: nowrap;
  }

  th,
  td {
    width: auto;
  }

  th:nth-child(4),
  td:nth-child(4) {
    width: 100%;
  }

  th {
    padding: 1rem #{$padding-table-balancete}rem;
  }

  td {
    padding: 0.5rem #{$padding-table-balancete}rem;
  }

  .recuar-1 {
    td:nth-child(4) {
      padding-left: #{$padding-table-balancete}rem;
    }
  }

  .recuar-2 {
    td:nth-child(4) {
      padding-left: #{2 * 0.5 + $padding-table-balancete}rem;
    }
  }

  .recuar-3 {
    td:nth-child(4) {
      padding-left: #{3 * 0.5 + $padding-table-balancete}rem;
    }
  }

  .recuar-4 {
    td:nth-child(4) {
      padding-left: #{4 * 0.5 + $padding-table-balancete}rem;
    }
  }

  .recuar-5 {
    td:nth-child(4) {
      padding-left: #{5 * 0.5 + $padding-table-balancete}rem;
    }
  }

  .recuar-6 {
    td:nth-child(4) {
      padding-left: #{6 * 0.5 + $padding-table-balancete}rem;
    }
  }
}

.hidden-table-custom-header-class {
  display: none;
}

.table thead th {
  &:focus {
    outline: none;
  }

  &.sortable:focus {
    background: #eeeeee;
  }
}

.react-bootstrap-table .table {
  border-bottom: 2px solid #ebedee;
}

.table-min-height {
  min-height: 12.5rem;
}

.table-no-results {
  min-height: 15rem;
}

.no-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.4rem 0 1rem 0;

  p {
    font-size: 1rem;
    color: $secondary;
    margin-bottom: 2rem;
  }

  img {
    max-height: 260px;
  }
}

.no-results-bloco {
  margin: 2rem 0;

  img {
    max-height: 180px;
  }

  p {
    margin: 0;
  }
}

// Table Legenda
.table-legenda {
  background-color: $gray-200;
  text-transform: uppercase;
  padding: 0.95rem;
}

// Popover
.popover {
  box-shadow: 0 0 30px 0px rgba($color: #000000, $alpha: 0.2) !important;
  z-index: 1040;
}
.bs-popover-bottom > .arrow::before,
.bs-popover-auto[x-placement^='bottom'] > .arrow::before {
  border-bottom-color: rgba(0, 0, 0, 0) !important;
}

// Datepicker
.react-datepicker-popper {
  display: none !important;

  .react-datepicker {
    box-shadow: 0 0 30px 0px rgba(0, 0, 0, 0.15);
    color: #888;
  }

  &.show-picker {
    display: block !important;
  }

  .react-datepicker__day,
  .react-datepicker__current-month,
  .react-datepicker__day-name,
  .react-datepicker__time-name {
    color: #888 !important;
  }

  .react-datepicker__day--disabled {
    color: #ccc !important;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__day--selected.react-datepicker__day--today {
    color: #fff !important;
    background-color: $primary !important;
  }

  .react-datepicker__day--today {
    background-color: $gray-600 !important;
    color: white !important;
    border-radius: 0.3rem !important;
  }

  .react-datepicker__month--selected,
  .react-datepicker__month--in-selecting-range,
  .react-datepicker__month--in-range {
    color: white !important;
  }

  .react-datepicker__month--in-range.react-datepicker__month--disabled {
    color: white !important;
  }

  .react-datepicker__month--disabled {
    color: #ddd !important;
  }
}

// Modal
.modal-backdrop {
  background-color: rgba($color: #000000, $alpha: 0.9);
}

.modal-content {
  border-radius: 0.25rem;
}

.modal-header {
  background-color: #eee;
  border: none;
  padding: 1rem 1.5rem;
}

.modal-body {
  padding: 1.5rem;
}

.modal-footer {
  border-radius: 0px;
  padding: 1.5rem;
}

.modal-operacoes-add {
  .modal-dialog {
    max-width: 320px;
  }
}

// Filter Table
.filter-table {
  .btn.open-filter {
    &.open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    box-shadow: none;

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  .wrapper-filter {
    border-top-right-radius: 0.15rem;
    border-bottom-right-radius: 0.15rem;
    border-bottom-right-radius: 0.15rem;

    @include media-breakpoint-down(md) {
      border-top-right-radius: 0;
    }
  }
}

// button-group
.button-group {
  .buttons {
    @include media-breakpoint-down(md) {
      width: 100%;
      padding: 0 !important;
      // margin-bottom: 1rem;

      .btn {
        width: 100%;
        margin-bottom: 1rem;
      }
    }
  }
}

// Button Light com ações
table {
  .btn-light {
    background-color: $gray-100;
    box-shadow: none;
    &:hover,
    &:active {
      background-color: $gray-300;
    }
  }
}

// Links footer
@media (max-width: 992px) {
  .links-footer {
    list-style-type: none;
  }
}

// Toast
.Toastify__toast-container {
  padding: 1rem;
}

.Toastify__toast {
  border-radius: 4px !important;
  padding-left: 1rem !important;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.3) !important;
}

.Toastify__toast--success {
  background-color: $info !important;
}

.Toastify__toast--error {
  background-color: $danger !important;
}

// Dropzone
.dropzone {
  min-height: inherit;

  h3 {
    font-size: 1.2rem;
    margin: 0;
  }

  .dz-message {
    padding: 1.5rem;
  }
}

.dropzone-previews {
  .avatar-sm {
    height: 8rem;
    width: 8rem;
  }

  .avatar-title {
    font-size: 0.75rem;
  }
}
// Tags Input
.tags-input-custom {
  padding: 0.75rem 0.75rem 0;
  height: inherit;
  margin-bottom: 1rem;
  .react-tagsinput-input {
    width: 100%;
    padding: 0.25rem 0 0;
    margin: 0 0 1rem;
  }
}

// Login
.signup-ordivider {
  margin: 1.5em auto;
  position: relative;
  font-weight: 200;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #919191;
  text-transform: uppercase;
  height: 20px;
  z-index: 1;

  &:before {
    position: absolute;
    content: '';
    left: 0;
    top: 9px;
    height: 1px;
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#d3d3d3),
      color-stop(42%, #d3d3d3),
      color-stop(42.1%, #fff),
      color-stop(57.9%, #fff),
      color-stop(58%, #d3d3d3)
    );
    background: linear-gradient(90deg, #d3d3d3, #d3d3d3 42%, #fff 42.1%, #fff 57.9%, #d3d3d3 58%);
    width: 100%;
    z-index: -1;
  }
}

// Gráficos
.chart-demo {
  text-align: center;

  h4 {
    text-align: left;
  }

  // img {
  //   // max-width: 350px;
  // }
}

// Form
.custom-switch {
  // padding-top: 0.5rem;
  margin-left: -5px;
}

.form-control:disabled,
.form-control[readonly] {
  cursor: not-allowed;
  background-color: $gray-200;
  opacity: 1;
}

.react-select__control--is-focused,
.form-control:focus .custom-select.is-invalid:focus,
.form-control.is-invalid:focus,
.custom-select:invalid:focus,
.form-control:invalid:focus,
.custom-select.is-valid:focus,
.form-control.is-valid:focus,
.custom-select:valid:focus,
.form-control:valid:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
}

.fake-ready-only {
  .form-group {
    margin-bottom: 2rem;
  }

  label {
    padding: 0 0.9rem;
  }

  .item-ready-only {
    margin: 0 0.9rem 2rem;
    min-height: 1.375rem;
  }

  p,
  .react-select,
  textarea {
    border-bottom: 1px solid $gray-300;
    margin: 0 0.9rem 2rem;
    min-height: 1.375rem;
  }

  input:read-only,
  textarea:read-only {
    background-color: white !important;
    border-color: transparent;
    // border-bottom-color: $gray-200;
    border-radius: 0;
    margin: 0;
    border-bottom: 1px solid $gray-300;
  }

  input:-internal-autofill-selected {
    background-color: white !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
  }

  .is-valid,
  .is-invalid {
    position: relative;
    border-bottom: 1px solid $gray-300;

    &::before {
      content: '';
      position: absolute;
      display: block;
      width: 1rem;
      height: 1rem;
      right: 0;
      bottom: 0.5rem;
      background-repeat: no-repeat;
    }
  }

  .is-valid {
    border-color: $primary;

    &::before {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23a521d8' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    }
  }

  .is-invalid {
    border-color: $danger;

    &::before {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fa5c7c' viewBox='-2 -2 7 7'%3E%3Cpath stroke='%23fa5c7c' d='M0 0l3 3m0-3L0 3'/%3E%3Ccircle r='.5'/%3E%3Ccircle cx='3' r='.5'/%3E%3Ccircle cy='3' r='.5'/%3E%3Ccircle cx='3' cy='3' r='.5'/%3E%3C/svg%3E");
    }
  }
}

.fake-required {
  margin: 0 !important;
  padding: 0 !important;
  opacity: 0 !important;
  height: 0 !important;
  border-width: 1px !important;
}

.borderview {
  p {
    border-bottom: 1px solid $gray-300;
    padding-bottom: 0.5rem;
    line-height: 1;
    min-height: 1.5rem;
    &.textarea {
      line-height: 1.5;
      overflow: hidden;
    }
  }
}

fieldset {
  border: 1px solid $gray-300 !important;
  padding: 0 1.4em 1.4em 1.4em !important;
  margin: 0 0 1.5em 0 !important;
  box-shadow: 0px 0px 0px 0px $gray-300;
  height: auto;

  &.collapsed {
    padding-bottom: 0 !important;
    // height: 25px;
  }

  legend {
    font-size: 1.2em !important;
    font-weight: bold !important;
    text-align: left !important;
    width: auto;
    padding: 0 10px;
    border-bottom: none;
    color: $gray-700;
  }
}

.card {
  .icon-button-collapse {
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
  }
}

.card-border {
  border: 2px solid $gray-300;
  margin-top: -1px;

  .card-body {
    border-top: 2px solid $gray-300;
  }

  .card-header {
    border-bottom: none;
    border-radius: 0;
  }
}

// Radio Button
.form-group {
  .custom-control {
    margin-top: 0.54rem;
  }
}

.custom-switch {
  display: block;
}

.custom-switch .custom-control-label {
  cursor: pointer;
}

.custom-switch .custom-control-label::after {
  left: calc(-1.75rem + 2px);
  width: calc(1.5rem - 4px);
  height: calc(1.5rem - 4px);
}

.custom-switch .custom-control-label::after {
  border-radius: 1rem;
  top: 0;
}

.custom-switch .custom-control-label::before {
  left: -1.75rem;
  width: 2.25rem;
  border-radius: 1rem;
}

.custom-control-label::before {
  position: absolute;
  left: -1.5rem;
  width: 1rem;
  height: 1.5rem;
  top: -2px;
}

// Checkbox
.custom-checkbox {
  .custom-control-label::after {
    top: 0.125rem;
    left: -1.4rem;
  }

  .custom-control-label::before {
    width: 1.2rem;
    height: 1.2rem;
    top: 0;
  }
}

// Dualist Box
.servicos-list-box {
  .rdl-move-all {
    display: none;
  }
}

// Spinner
.spinner-border {
  height: 1rem;
  width: 1rem;
  display: inline-block;
}

//Upload
.certificado-upload {
  .dz-message {
    padding: 0.75rem;
    h3 {
      font-size: 0;
    }
  }
}

.imposto-upload {
  .dz-message {
    padding: 0.75rem;
    h3 {
      font-size: 0;
    }
  }
}

//Icons
table {
  .check-icon,
  .warn-icon,
  .close-icon {
    font-size: 1.55rem;
    text-align: center;
  }
}
.check-icon {
  color: $greenDark;
}

.warn-icon {
  color: $danger;
}

.close-icon {
  color: $danger;
}

.label-categoria-empresa {
  display: block;
  position: relative;
  padding-left: calc(1rem + 5px);
  &.yellow {
    &:before {
      background-color: $warning;
    }
  }
  &.red {
    &:before {
      background-color: $danger;
    }
  }
  &:before {
    content: '';
    border-radius: 10px;
    display: block;
    left: 5px;
    height: 10px;
    width: 10px;
    top: calc(50% - 5px);
    position: absolute;
    background-color: transparent;
  }
}

// Responsivo
@media (max-width: 992px) {
  .topbar-nav {
    .side-nav-link {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .topbar-right-menu > .notification-list {
    // padding-left: 0 !important;
    // margin: 0 !important;
    .icon.mdi.mdi-percent,
    .icon.mdi.mdi-folder-account {
      display: none;
    }
    // > a {
    //   // padding-left: 0 !important;
    //   // margin: 0 !important;
    // }
  }

  .navbar-custom .dropdown.show {
    // background-color: darken($success, 20);
    .button-menu-mobile,
    .nav-link {
      color: white !important;
    }
  }

  .topbar-nav {
    margin-bottom: 1rem;
  }

  .escritorio-contabil-header {
    display: none;
  }

  .nav-tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 1rem;
    &.nav-bordered {
      li a {
        padding-left: 0;
        padding-right: 0;
        margin: 0 0.75rem 0.5rem;
        &.active {
          padding-bottom: calc(0.625rem - 4px);
        }
      }
    }
  }
}

.select-loading {
  &::after {
    display: inline-block;
    animation: loadingDottys steps(1, end) 1.5s infinite;
    content: '';
  }
}

@keyframes loadingDottys {
  0% {
    content: '';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
  100% {
    content: '';
  }
}

// Rateio

// .rateio-group {
//   .rateio-group-item {
//     &:hover {
//       background-color: $gray-100;
//     }
//   }
// }

.error-boundary-fallback {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 2.5rem;
    font-weight: bold;
    color: $primary;
    margin: 3rem 0 1rem 0;
  }

  h2 {
    font-size: 1.2rem;
    color: $secondary;
    margin: 0;
  }

  a {
    margin: 2.5rem 0 3rem 0;
  }

  img {
    max-width: 600px;
  }
}

.maintenance {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: $bg-color;
  padding: 1rem;

  img {
    width: auto;
  }

  h4 {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
  }

  p {
    font-size: 1.2rem;
    text-align: center;
  }
}

.alert-aceite-estrangeiro {
  background-color: #ffffff;
  border: 1px $warning solid;
  color: $dark;
}
