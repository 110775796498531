// Core files
@import './node_modules/bootstrap/scss/functions';
@import './node_modules/bootstrap/scss/variables';
@import 'config/FT/variables';
@import 'config/FT/custom-variables';
@import './node_modules/bootstrap/scss/bootstrap';

// Vendors
@import './node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min';

// Structure
@import 'custom/structure/general';
@import 'custom/structure/left-menu';
@import 'custom/structure/topbar';
@import 'custom/structure/right-sidebar';
@import 'custom/structure/page-head';
@import 'custom/structure/footer';

// Horizontal nav
@import 'custom/structure/horizontal-nav';

// Fonts
@import 'custom/fonts/cerebri';

// Components
@import 'custom/components/mixins';
@import 'custom/components/accordions';
@import 'custom/components/avatar';
@import 'custom/components/breadcrumb';
@import 'custom/components/buttons';
@import 'custom/components/badge';
@import 'custom/components/card';
@import 'custom/components/dropdown';
@import 'custom/components/forms';
@import 'custom/components/modal';
@import 'custom/components/nav';
@import 'custom/components/pagination';
@import 'custom/components/popover';
@import 'custom/components/print';
@import 'custom/components/progress';
@import 'custom/components/reboot';
@import 'custom/components/switch';
@import 'custom/components/tables';
@import 'custom/components/type';
@import 'custom/components/utilities';
@import 'custom/components/widgets';
@import 'custom/components/social';
@import 'custom/components/steps';
@import 'custom/components/preloader';

// Pages
@import 'custom/pages/authentication';
@import 'custom/pages/buttons-demo';
@import 'custom/pages/error';
@import 'custom/pages/faq';
@import 'custom/pages/grid-demo';
@import 'custom/pages/icons-demo';
@import 'custom/pages/maintenance';
@import 'custom/pages/tasks';
@import 'custom/pages/email';

// Plugins
@import 'custom/plugins/apexcharts';
@import 'custom/plugins/britechart';
@import 'custom/plugins/chartjs';
@import 'custom/plugins/datatable';
@import 'custom/plugins/datepicker';
@import 'custom/plugins/draft-wysiwyg';
@import 'custom/plugins/dropzone';
@import 'custom/plugins/maps';
@import 'custom/plugins/metisMenu';
@import 'custom/plugins/react-select';
@import 'custom/plugins/slimscroll';
@import 'custom/plugins/simplemde';
@import 'custom/plugins/typehead';

// Custom
@import 'config/FT/custom';

// Hyper Icons
@import './icons.scss';
