//
// forms.scss
//

// Form-control light
.form-control-light {
    background-color: $gray-100 !important;
    border-color: $gray-100 !important;
}


// Form elements (Color and Range)
input.form-control[type="color"],
input.form-control[type="range"] {
    min-height: 39px;
}


// Custom select
.custom-select.is-invalid, .form-control.is-invalid,.custom-select:invalid, .form-control:invalid,
.custom-select.is-valid, .form-control.is-valid, .custom-select:valid,.form-control:valid {
    &:focus {
        box-shadow: none !important;
    }
}

select.form-control {
    &:not([size]):not([multiple]) {
        height: $input-height;
    }
}

select.form-control-sm {
&:not([size]):not([multiple]) {
    height: $input-height-sm;
}
}

.custom-click-copy-clipboard {
  position: relative;

  .form-control {
    padding-right: 40px;
  }

  .link-click-copy-clipboard {
    position: absolute;
    width: 30px;
    padding: 5px;
    top: 4px;
    right: 6px;
    cursor: pointer;
  }
}
